import React from 'react';
import { Row, Col, Button, Icon } from 'antd';
import PreRegPopupForm from '../index/PreRegPopupForm';

function Footer({ twitter, facebook, siteUrl }) {
  return (
    <>
      <div className='content1'>
        <div className='content1-title'>
          <h1>It's about the time you take back control</h1>
        </div>
        <div className='content1-subheading'>
          <h4>Get early access to OKR BOARD</h4>
        </div>
        <div className='content1-btn'>
          <PreRegPopupForm btnText='Register' type='primary' size='large' />
        </div>
      </div>
      <footer id="footer" className="dark">
        <Row className="bottom-bar" type='flex'>
          <Col lg={6} sm={24}>
            <span>Made with <Icon type="heart" theme="filled" /> at
          <Button size='small' type='link' href='https://logicwind.com' target='_blank'>Logicwind</Button></span>
            {/* <div className="translate-button">
            <Button ghost size="small" >
              English
            </Button>
          </div> */}
          </Col>
          <Col lg={18} sm={24}>
            {twitter && <span><Button type='link' size='large' href={twitter} icon="twitter" target="_blank" rel="noopener noreferrer"></Button></span>}
            {facebook && <span><Button type='link' size='large' href={facebook} icon="facebook" target="_blank" rel="noopener noreferrer"></Button></span>}
            <span><Button type='link' size='large' className="site-nav-item" href={`https://feedly.com/i/subscription/feed/${siteUrl}/rss/`} icon="notification" target="_blank" rel="noopener noreferrer"></Button></span>
          </Col>
        </Row>
      </footer>
    </>
  );
}


export default Footer;
