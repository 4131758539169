import React, { useState } from 'react'
import { Button, Modal, Input, Icon, Form } from 'antd'
import validator from 'validator'
import { Link } from 'gatsby'
const PreRegPopup = (props) => {
    const [state, setState] = useState(false)
    const [email, setEmail] = useState({ user_email: '' })
    const { btnText, size } = props
    const { getFieldDecorator } = props.form
    const { type } = props
    return (
        <>
            <Button size={size} className='pre-reg-btn' type={type} onClick={() => setState(true)}>
                {btnText}
            </Button>
            <Modal
                title="Pre Register"
                style={{ top: 150 }}
                visible={state}
                okText='Submit'
                onOk={() => setState(false)}
                onCancel={() => setState(false)}
            >
                <>
                    <p>We are just a step away, but you don’t have to wait</p>
                    <br />
                    <form autoComplete='off' action="https://app.moosend.com/subscribe/1afae7dc-2942-420e-bf08-4c74f0f29981" method="post" id="ms-sub-form" target="_blank">
                        <div className="login-form">
                            <Form.Item>
                                <Input
                                    placeholder="Enter your email"
                                    size="large"
                                    allowClear
                                    name="ms-email"
                                    id="email"
                                    onChange={(e) => setEmail({ user_email: e.target.value })}
                                    prefix={< Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                />
                            </Form.Item>
                            <Form.Item className='submit-button'>
                                <Button
                                    size='default'
                                    type='primary'
                                    htmlType='submit'
                                    disabled={!validator.isEmail(email.user_email)}
                                >
                                    Submit
                                </Button>
                            </Form.Item>
                        </div>
                    </form>
                </>
            </Modal>
        </>
    )
}
const PreRegPopupForm = Form.create({ name: 'popup' })(PreRegPopup);
export default PreRegPopupForm
