import React from 'react';
import { ScrollingProvider, Section } from 'react-scroll-section';
import { Menu, Item } from '../components/Menu';
// import Sider from "antd/lib/layout/Sider";
// import { Layout } from "antd";
import { Layout, Breadcrumb, Icon, Affix, Button } from 'antd';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import DownloadTemplate from '../components/TemplateDownload';

// const { SubMenu } = Menu;
// const { Header, Content, Footer, Sider } = Layout;
class okr extends React.Component {
    state = {
        marginTop: 45
    }

    listenScrollEvent = e => {
        // console.log(this.state.height)
        console.log('Scroll: ', window.scrollY)
        console.log('Height:', document.body.scrollHeight)
        console.log(document.body.scrollHeight - document.body.scrollHeight * 0.5, ' : Subtraction')
        if (window.scrollY > 170) {
            this.setState({ marginTop: '-160px' })
        } else {
            this.setState({ marginTop: 45 })
        }
        if (window.scrollY > (document.body.scrollHeight * 0.7)) {
            this.setState({ marginTop: '-500px' })
        }

        // if (window.scrollY > (document.body.scrollHeight - document.body.scrollHeight * 0.66)) {
        //     this.setState({ marginTop: '-520px' })
        // }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenScrollEvent)
    }
    render() {
        return (
            <>
                <ScrollingProvider scrollBehavior="smooth">
                    <div id="okr-header">
                        <Header />
                    </div>
                    <div id="okr-hero">
                        <div>
                            <h1>OKR: Objectives and Key Results explained</h1>
                            <p>Learn about OKRs - Google's goal setting system and create your own OKRs from a FREE template</p>
                        </div>
                        <div className='okr-image'>
                            <img src={require('../images/okr-hero.svg')} />
                        </div>
                    </div>
                    <div className='okr-content-wrapper'>
                        <div id="okr-left_side" style={{ marginTop: this.state.marginTop }}>
                            <Menu>
                                <Item section="definition">Definition of OKR</Item>
                                <Item section="introduction">Introduction of OKR</Item>
                                <Item section="components">Components of OKR</Item>
                                <Item section="benefits">Benefits of OKR</Item>
                                <Item section="usage">How to use it ?</Item>
                                <Item section="okr_101">OKR 101</Item>
                                <Item section="okr_resources">OKR Resources</Item>
                            </Menu>
                        </div>


                        <div id="okr-content">
                            <Section id="definition">
                                <hr />
                                <h1>Definition of OKR</h1>
                                <p>
                                    OKR is an acronym for Objective and Key Results, a goal management framework used by leading
                                    silicon valley companies as well as growing startups to align and implement strategies for
                                    growth. It streamlines the focus of employees towards a unified goal while increasing
                                    transparency and discipline. The main purpose of any OKR is to ensure that the team leader
                                    and the entire team is working
						    </p>
                                <p>
                                    The main purpose of any OKR is to ensure that the team leader and the entire team is working
                                    towards the same purpose. To make it more professional, teams use different tools that help
                                    in quick implementation of OKRs in the company.
						    </p>
                                <p>
                                    The conventional planning methods consume an ample amount of time as well as resources in
                                    today’s technology-driven generation, whereas OKRs are considerably lightweight, and
                                    provides a clear vision to steer towards the company’s vision.
						    </p>
                            </Section>
                            <hr />
                            <Section id="introduction">
                                <hr />
                                <h1>Introduction to OKR - Objectives Key Results</h1>
                                <p>
                                    Introduced in 1968 by Intel president Andy Grove, OKR was traditionally created as objective
                                    and goals. The entire system was subjected to answer two major questions -
						    </p>
                                <h2>
                                    <strong>
                                        <i>Where do I want to go?</i>
                                    </strong>
                                </h2>
                                <h2>
                                    <strong>
                                        <i>How do I pace myself to see if I am getting there?</i>
                                    </strong>
                                </h2>
                                <p>
                                    But the entire concept gained momentum in 1999 when John Doerr, a venture capitalist invested in Google and skyrocketed its growth from 40 employees to more than 60,000 today. Hence, OKRs got recognition via silicon valley, widespread from multinational giants like Netflix, Amazon, Dell, Dropbox, Facebook, Samsung and Twitter.
					        </p>
                                <p>
                                    OKR is a concept widely accepted in the digital landscape as well as offline market to  inspire and motivate their employees
                            </p>
                                <p>
                                    Objective and Key Results is a framework to not only charter your end results but also draft a way to measure the success of the key results. Because if an outcome is not measurable, its success rate is considered ambiguous.
                            </p>
                            </Section>
                            <hr />
                            <Section id="components">
                                <hr />
                                <h1>Components of OKR</h1>
                                <p>
                                    So, as the name indicates OKRs have two main components - <b>Objectives and Key Results.</b>
                                </p>
                                <div className='okr-components'>
                                    <div>
                                        <h1><span><img src={require('../images/target.svg')} /></span>Objectives</h1>
                                        <p>Objectives in simple terms, is a description of the goal you want to achieve. An objective requires a great deal of thinking, it should be a milestone that tests your limits and is ambitious for your company. An objective should be short, easily-memorable and inspirational. It acts as an end destination for the vision of your company.</p>
                                    </div>
                                    <div>
                                        <h1><span><img src={require('../images/checklist.svg')} /></span>Key Results</h1>
                                        <p>These are the numerical values used to scale the growth of an objective. Key results are a set of quantitative and achievable metrics that define the success of an objective. Under each objective, define 3-4 key results which are ambitious and adds value to your goal. Key results should be challenging, exponential growth, limited and measurable.</p>
                                    </div>
                                </div>
                                <h1>OKR Examples</h1>
                                <p>Let’s say a marketing agency wants to revamp its user acquisition strategy, their major goal is to get more customers onboard. That defines our objective: Customer Acquisition.
But how do we measure whether there is a progressive growth in customer acquisition, this is where we require Key Results.</p>
                                <div className='okr-example'>
                                    <div>
                                        <h1>Objective</h1>
                                        <ul>
                                            <li><i><b>Customer Acquisition</b></i></li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h1>Key Result</h1>
                                        <ul>
                                            <li><i>Increase email open rate from X% to Y%</i></li>
                                            <li><i>Enhance Web traffic by X%</i></li>
                                            <li><i>Gain X+ monthly signups</i></li>
                                            <li><i>Revenue churn for CA by X%</i></li>
                                        </ul>
                                    </div>
                                </div>
                                <span>
                                    <p>
                                        These metrics define organic growth in customer acquisition. The key results optimization is crucial, if the result is 100% then your achievement wasn’t ambitious enough. An ideal success rate for an OKR is between 70- 75 %, this showcases that your team had worked beyond their perceivable limits.
                                        </p>
                                    <q><b>If your OKR looks easy, it’s not good enough!</b></q>
                                </span>
                            </Section>
                            <hr id='okr-hr' />
                            <div className='how-to-write-okr'>

                                <h1>FREE template to create OKRs for your organisation</h1>
                                <p>Get this excel OKR template to get started with OKRs for your business</p>
                                <DownloadTemplate />
                            </div>
                            <hr />
                            <Section id="benefits">
                                <hr />
                                <h1>Benefits of OKR</h1>
                                <p>
                                    The process of forming an OKR is simple and yields even better benefits. It follows a methodology of keeping the objective of growth at the forefront while cutting the confusion of what is a company striving to achieve.
                                </p>
                                <p id='benefit'>A few benefits of OKR are: </p>

                                <div className='okr-benefits'>
                                    <div>
                                        <h3><span><img src={require('../images/goal.svg')} /></span>Extended Goals</h3>
                                        <p>OKRs are formulated to concentrate on the quality outcome rather than just ticking off the checklist. Besides being inspirational, these stretched goals enable the employees to push their limits for an organic outcome. Tie up your OKRs to your annual company goals to have a better understanding of your business growth.</p>
                                    </div>
                                    <div>
                                        <h3><span><img src={require('../images/agile.svg')} /></span>Agility</h3>
                                        <p>The shorter cadence leads to better and swifter use of OKR. It doesn’t take much time to implement and assign these outcomes. The time period of your cadence is proportionally linked to your business certainty. With quarterly OKRs, it is facile to achieve your agile goals, reducing the risk of fluctuations in revenue churn.</p>
                                    </div>
                                    <div>
                                        <h3><span><img src={require('../images/transparency.svg')} /></span>Transparency</h3>
                                        <p>The major purpose of an OKR is to have your organization working towards a unified direction. Usually, in a company, department-wise OKRs are visible to all the employees in order to have an insight into how their work fits into the organization's growth.</p>
                                    </div>
                                    <div>
                                        <h3><span><img src={require('../images/accounting.svg')} /></span>Accountability</h3>
                                        <p>Managers are adopting this method to have a clear conscience about their team’s input.  A specific team is assigned an OKR, and they are accountable for its success rate and positive outcomes aligned with the company. This also boosts engagement while prioritizing the company’s objectives and goals. Meanwhile setting a  foundation for clear communication amongst the individuals.</p>
                                    </div>
                                    <div>
                                        <h3><span><img src={require('../images/collaboration.svg')} /></span>Flexible Collaboration</h3>
                                        <p>Every company strives hard to get multiple teams on-board for a single project, as the progress advances the objective loses sight. The most effective way to have a flexible collaboration amongst multiple teams is by creating a shared OKR. This helps in tracking the progress and maintaining unified sync within the teams.</p>
                                    </div>
                                    <div>
                                        <h3><span><img src={require('../images/save-time.svg')} /></span>Time-Saving</h3>
                                        <p>The setting up of goals on OKR is a lightweight, straightforward and simple approach. Companies adopt OKR methodology to reduce the time spent on meetings and strategizing goals. Invest your time is setting up ambitious goals on OKR that aligns with your company’s objective, rather than having regular progress check meetings which consume time and resources.</p>
                                    </div>
                                </div>

                            </Section>
                            <hr />
                            <Section id="usage">
                                <hr />
                                <h1>How to use it ?</h1>
                                <p>
                                    OKRs can be a boon or a bane depending upon how they are used in an organization.
    A golden tip to start with OKR is to align it with your company’s goals and objectives, by doing this you can strategize the results and outcomes for better performance and growth.
                                </p>
                                <p>
                                    Once you have chartered your objectives, make sure they are communicated to everyone in the organization. There should be a regular update on the results to maintain transparency amidst the employees.
                                </p>
                                <p>
                                    OKRs are usually set up in the start with high motivation, in the Farsight, it fades away. To keep up the ambitious nature of the OKRs, decouple the compensation from evaluation to encourage employees in taking up challenging goals while having a clear conscience that it won’t affect their performance review or job security.
                                </p>
                                <p>
                                    Current evolution sector is fast-paced and flexible, hence setting up Annual or Quarterly OKRs can be a tedious task. If your objectives demand a change, make sure that the criteria are communicated well. Once you’ve reviewed your OKR, make sure you fall into the 70-75% success rate, if you gained a 100%, your goals weren’t ambitious enough.
                                </p>
                            </Section>
                            <hr />
                            <Section id="okr_101">
                                <hr />
                                <h1>OKR 101</h1>
                                <p>If you have the right set of goals and objectives, creating an OKRs is not a tough task. But there are certain things you should adhere to while drafting your OKRs in order to plunge your way to success.</p>
                                <ul className='okr-checklist'>
                                    <li>Focus your OKRs on the efforts and performance rather than results.</li>
                                    <li>Set up ambitious goals, not a task list.</li>
                                    <li>Limit the numbers of KRs to keep it simple and easy to remember.</li>
                                    <li>Keep your OKRs time-bound, you don’t want to spend the entire time on a single objective.</li>
                                    <li>Always keep a check on your progress, avoid “set it - forget” it mindset.</li>
                                    <li>Stretch your goals, focus on your specific moonshots and roof shot.</li>
                                    <li>70% is the new 100%.</li>
                                    <li>Don’t let your OKR hang in silos - communicate them with your team.</li>
                                    <li>Use your OKR as a management tool rather than a performance evaluation tool.</li>
                                    <li>Don’t try to copy traditional OKR, every company has a different way to advance towards success.</li>
                                </ul>
                            </Section>
                            <hr id='okr-hr' />
                            <div className='how-to-write-okr'>

                                <h1>FREE template to create OKRs for your organisation</h1>
                                <p>Get this excel OKR template to get started with OKRs for your business</p>
                                <DownloadTemplate />
                            </div>
                            <hr />
                            <Section id='okr_resources'>
                                <hr />
                                <h1>OKR Resources</h1>
                                <p className='okr-podcast-subheading'>Here's a great podcast that covers challenges of growing tech businesses along with some deep dives into the OKR (Objectives and Key Results) methodology.</p>
                                {/* <p>Here's a quick video from John Doerr himself on OKRs and Goal Setting at Google and Intel</p> */}
                                <div className='okr-frame'>
                                    <iframe src="https://play.pod.co/giant-talk-the-worlds-first-okr-podcast/" width="100%" scrolling="no" style={{ border: 'none', overflow: 'hidden', height: '500px', maxWidth: '750px' }}></iframe>
                                    {/* //! OKR-Video
                                    // TODO: */}
                                    {/* <iframe className='okr-video' width="100%" height="470" src="https://www.youtube.com/embed/t-yeDb7stlw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                                </div>
                            </Section>


                        </div>
                    </div>
                </ScrollingProvider>
                <div>
                    <Footer className='okr-footer' />
                </div>
            </>
        );
    }
}

export default okr;
