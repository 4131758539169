import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { Row, Col, Menu } from 'antd'
import PreRegPopupForm from '../index/PreRegPopupForm'
import MobileMenu from './MobileMenu';
import Measure from 'react-measure';

const Navigation = ({ siteUrl, twitter, facebook, menuMode, navData, navClass = 'default-nav' }) => {
  const items = navData && navData.map((navItem, i) => {
    let el = <Link className={navClass} to={navItem.url} key={i}><span>{navItem.label}</span></Link>
    if (navItem.url.match(/^\s?http(s?)/gi)) {
      el = <a className={navClass} href={navItem.url} key={i} target="_blank" rel="noopener noreferrer"><span>{navItem.label}</span></a>
    }
    return <Menu.Item key={i}>{el}</Menu.Item>
  })

  return <>
    <Menu mode={menuMode} id="nav" key="nav">
      <Menu.Item><Link to="/">Home</Link></Menu.Item>
      <Menu.Item><Link to="/blog">Blog</Link></Menu.Item>
      <Menu.Item><Link to="/what-is-okr">What is OKR</Link></Menu.Item>
      {
        menuMode === 'inline' && (
          <Menu.Item key="preview">
            <PreRegPopupForm btnText='Register' type='primary' size='default' />
          </Menu.Item>
        )
      }
    </Menu>
  </>
}

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuVisible: false,
      menuMode: 'horizontal',
      visible: false,
      dimensions: { width: -1, height: -1 }
    }
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { menuMode, menuVisible } = this.state;
    const { width } = this.state.dimensions
    const { siteTitle, logo, navData, facebook, twitter, siteUrl } = this.props;
    const menu = <Navigation navData={navData} menuMode={menuMode} twitter={twitter} facebook={facebook} siteUrl={siteUrl} />

    return (
      <Measure
        bounds
        onResize={(contentRect) => {
          this.setState({ dimensions: contentRect.bounds })
        }}
      >
        {({ measureRef }) =>
          <div ref={measureRef} id="header" className="header" >
            {
              (width < 900) ?
                <>
                  <MobileMenu />
                  <Row type="flex" className='mobile-header'>
                    <Col className='logo-div' xxl={4} xl={5} lg={8} md={8} sm={24} xs={24}>
                      <div id="logo" to="/">
                        <Link to="/"><img src={require('../../images/logo.svg')} /></Link>
                      </div>
                    </Col>
                  </Row>
                </>
                :
                <>
                  <Row type="flex">
                    <Col className='logo-div' xxl={4} xl={5} lg={8} md={8} sm={24} xs={24}>
                      <div id="logo" to="/">
                        <Link to="/"><img src={require('../../images/logo.svg')} /></Link>
                      </div>
                    </Col>
                    <Col xxl={20} xl={19} lg={16} md={16} sm={0} xs={0}>
                      <div className="header-meta">
                        <div id="preview">
                          <PreRegPopupForm btnText='Register' type='primary' size='default' />
                        </div>
                        <div id="menu">{menu}</div>
                      </div>
                    </Col>
                  </Row>
                </>
            }
          </div>
        }
      </Measure>
    );
  }
}


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
