import React from 'react'
import { Menu } from 'antd'
import PreRegPopupForm from '../index/PreRegPopupForm';
import { Link } from 'gatsby';

class MobileMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
        }
    }

    handleMenuClick() {
        this.setState({ menuOpen: !this.state.menuOpen });
    }

    handleLinkClick() {
        this.setState({ menuOpen: false });
    }

    render() {
        return (
            <div>
                <div className='mobilemenu-container'>
                    <MenuButton open={this.state.menuOpen} onClick={() => this.handleMenuClick()} color='white' />
                </div>
                <MyMenu open={this.state.menuOpen} />
            </div>
        )
    }
}
class MyMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open ? this.props.open : false,
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.open !== this.state.open) {
            this.setState({ open: nextProps.open });
        }
    }

    render() {
        const trans = this.state.open ? 'trans' : null
        return (
            <div className={`menu-wrapper ${trans}`}>
                <div className='menu-list'>
                    <Menu key="nav">
                        <Menu.Item><Link to="/">Home</Link></Menu.Item>
                        <Menu.Item><Link to="/blog">Blog</Link></Menu.Item>
                        <Menu.Item><Link to="/what-is-okr">What is OKR</Link></Menu.Item>
                        <Menu.Item key="preview" className='reg-btn'>
                            <PreRegPopupForm type='link' btnText='Register' size='default' />
                        </Menu.Item>
                    </Menu>
                </div>
            </div>
        )
    }
}
class MenuButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open ? this.props.open : false,
            color: this.props.color ? this.props.color : 'black',
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.open !== this.state.open) {
            this.setState({ open: nextProps.open });
        }
    }

    handleClick() {
        this.setState({ open: !this.state.open });
    }

    render() {
        const listTop = this.state.open === true ? 'list-top' : 'top-none'
        const listMiddle = this.state.open === true ? 'list-middle' : 'middle-none'
        const listBottom = this.state.open === true ? 'list-bottom' : 'bottom-none'
        return (
            <div className='menu-button-wrapper'
                onClick={
                    this.props.onClick
                        ?
                        this.props.onClick
                        :
                        () => {
                            this.handleClick();
                        }
                }
            >
                <div className={`${listTop} list`} />
                <div className={`${listMiddle} list`} />
                <div className={`${listBottom} list`} />
            </div>
        )
    }
}
export default class Main extends React.Component {
    render() {
        return (
            <div className='menu-main-component'>
                <MobileMenu />
            </div>
        )
    }
}
