import React from "react";
import { SectionLink } from "react-scroll-section";
import styled from "styled-components";

const Menu = styled.ul`
  z-index: 1;
  display: flex;
  flex-direction: column;
`;

const Item = styled.li`
  display: inline-block;
  cursor: pointer;
  transition: all 0.7s;
  padding: 20px 10px;
  font-weight: bold;
  font-size: 15px;
  color: ${props => (props.selected ? "#1890ff" : "#534F4C")};
  border-left: 3px solid
    ${props => (props.selected ? "#1890ff" : "#c4ccd1")};
`;

const MenuItem = ({ section, children }) => (

    <SectionLink section={section}>
        {
            link => (
                <Item onClick={link.onClick} selected={link.isSelected}>
                    {children}
                </Item>
            )}
    </SectionLink >
);

export { MenuItem as Item, Menu };

export default styled;
